/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  headerTitle: {
    id: "header.title",
    defaultMessage: "Orbit Viewer",
  },
  headerGeodata: {
    id: "header.geodata",
    defaultMessage: "GEODATA",
  },
  headerDatalayers: {
    id: "header.datalayers",
    defaultMessage: "Datalagen",
  },
  headerTableData: {
    id: "header.table.data",
    defaultMessage: "Tabel data",
  },
  headerFilemanagertitle: {
    id: "header.filemananger.title",
    defaultMessage: "Bestanden beheer",
  },
  headerDatagroups: {
    id: "header.datagroups",
    defaultMessage: "Datagroepen",
  },
  headerImagesAndWMFWFS: {
    id: "header.images.and.wms.wfs",
    defaultMessage: "AFBEELDINGEN EN WMS/WFS",
  },
  headerImages: {
    id: "header.images",
    defaultMessage: "Afbeeldingen",
  },
  headerWMSWFS: {
    id: "header.wms.wfs",
    defaultMessage: "WMS/WFS",
  },
  headerBaselayers: {
    id: "header.basiskaarten",
    defaultMessage: "BASELAYERS",
  },
  headerPublications: {
    id: "header.publications",
    defaultMessage: "PUBLICATIES",
  },
  headerLogout: {
    id: "header.logout",
    defaultMessage: "Uitloggen",
  },
  headerChangeDBPassword: {
    id: "header.change.db.password",
    defaultMessage: "Verander DB paswoord",
  },
  headerCancel: {
    id: "header.cancel",
    defaultMessage: "ANNULEREN",
  },
  headerSave: {
    id: "header.save",
    defaultMessage: "OPSLAAN",
  },
  headerChangeDatabasePassword: {
    id: "header.change.database.password",
    defaultMessage: "Verander database paswoord",
  },
  headerNewDatabasePassword: {
    id: "header.new.database.password",
    defaultMessage: "Nieuw database paswoord",
  },
  headerBackToDetail: {
    id: "header.back.to.detail",
    defaultMessage: "Terug naar detail",
  },
  headerStyleDatalayer: {
    id: "header.style.datalayer",
    defaultMessage: "Style voor datalaag:",
  },
  headerStyleDatagroup: {
    id: "header.style.datagroup",
    defaultMessage: "Style voor datagroep:",
  },
  headerNotifications: {
    id: "header.notifications",
    defaultMessage: "Meldingen",
  },
  headerUpload: {
    id: "header.upload",
    defaultMessage: "Uploaden",
  },
  headerProcess: {
    id: "header.process",
    defaultMessage: "Verwerken",
  },
  headerNoNewNotifications: {
    id: "header.no.new.notifications",
    defaultMessage: "Geen nieuwe meldingen!",
  },
});
