/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  titleVECTOR: {
    id: "datalayer.title.vector",
    defaultMessage: "Datalagen",
  },
  listviewBy: {
    id: "listview.by",
    defaultMessage: "door",
  },
  listviewDetails: {
    id: "listview.details",
    defaultMessage: "Details",
  },
  listviewDelete: {
    id: "listview.delete",
    defaultMessage: "Verwijderen",
  },
  listviewDeleteLowercase: {
    id: "listview.delete.lowercase",
    defaultMessage: "{singleItemName} verwijderen",
  },
  listviewCancel: {
    id: "listview.cancel",
    defaultMessage: "Annuleren",
  },
  listviewSearch: {
    id: "listview.search",
    defaultMessage: "zoeken",
  },
  listviewNone: {
    id: "listview.none",
    defaultMessage: "Geen",
  },
  listviewFound: {
    id: "listview.found",
    defaultMessage: "gevonden",
  },
  listviewKeyword: {
    id: "listview.keyword",
    defaultMessage: "voor de zoekterm",
  },
  listviewPleaseOne: {
    id: "listview.please.one",
    defaultMessage: "Gelieve een",
  },
  listviewUploadOne: {
    id: "listview.upload.one",
    defaultMessage: "te uploaden",
  },
  listviewDateCreated: {
    id: "listview.date.created",
    defaultMessage: "Datum aangemaakt",
  },
  listviewDateModified: {
    id: "listview.date.modified.sort",
    defaultMessage: "Datum bewerkt",
  },
  listviewCreated: {
    id: "listview.created",
    defaultMessage: "Aangemaakt",
  },
  listviewOn: {
    id: "listview.on",
    defaultMessage: "op",
  },
  listviewModifiedOn: {
    id: "listview.modified.on",
    defaultMessage: "bewerkt op",
  },
  listviewCount: {
    id: "listview.count",
    defaultMessage: "Aantal",
  },
  listviewSearchEnglish: {
    id: "listview.search.english",
    defaultMessage: "search",
  },
  listviewDeleteQuestion: {
    id: "listview.delete.question",
    defaultMessage: "Bent u zeker dat u deze {singleItemName} wilt verwijderen?",
  },
});
