import { BaseLayerListObjectType, LayerListObjectType, LayergroupListObjectType, PublicationListObjectType, TransferLayerListObjectType, User } from "@orbit/geo-core-shared";
import { InjectedIntl } from "react-intl";
import messages from "../containers/ListView/messages";

interface ListViewItem {
  dateModified?: Date;
  dateCreated: Date;
  user?: { firstName: string; lastName: string };
}

export const formatDate = (date: Date | string | number) => {
  return new Date(date).toLocaleDateString("nl-BE");
};

const _appendUserMeta = ({ user, intl }: { user?: User | null; intl: InjectedIntl }): string => {
  if (user) {
    return ` ${intl.formatMessage(messages.listviewBy)} ${user.firstName} ${user.lastName}`;
  }
  return "";
};

const _appendCreatedMeta = ({ date, intl }: { date?: Date | string | null; intl: InjectedIntl }): string => {
  if (date) {
    return ` ${intl.formatMessage(messages.listviewOn)} ${formatDate(date)}`;
  }
  return "";
};

const _appendModifiedMeta = ({ date, intl }: { date?: Date | string | null; intl: InjectedIntl }): string => {
  if (date) {
    return `, ${intl.formatMessage(messages.listviewModifiedOn)} ${formatDate(date)}`;
  }
  return "";
};

export const getItemMetaLabel = ({
  item,
  intl,
}: {
  item: LayerListObjectType | PublicationListObjectType | LayergroupListObjectType | TransferLayerListObjectType | BaseLayerListObjectType | ListViewItem | null;
  intl: InjectedIntl | any;
}): string => {
  if (!intl || !item) return "";
  return `${intl.formatMessage(messages.listviewCreated)}${_appendUserMeta({ user: item?.user, intl })}${_appendCreatedMeta({
    date: item?.dateCreated,
    intl,
  })}${_appendModifiedMeta({ date: item?.dateModified, intl })}`;
};
